@import url("https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext");
@import url("./norwester-font.css");

:root {
    --primary: #97144d !important;
    --tertiary: #193661 !important;
    --accent: #61b8ff !important;
    --black: #231f20 !important;
    --secblack: #555151 !important;
    --secblackone: #64707c !important;
    --secblacktwo: rgba(85, 81, 81, 0.5) !important;
    --secblackthree: #52555a !important;
    --grey: #445261 !important;
    --greyone: #64707c !important;
    --greytwo: rgba(128, 145, 163, 0.5) !important;
    --greythree: rgba(128, 145, 163, 0.3) !important;
    --greyfour: #64707c;
    --greyfive: #8f97a0 !important;
    --secondarygrey: #9a9393;
    --background: #f8f9f9 !important;
    --secondarybackground: #fafafa !important;
    --green: #27ae60 !important;
    --greenone: #219653 !important;
    --greentwo: #055f2b !important;
    --greenBackground: #edfff5 !important;
    --red: #eb5757 !important;
    --redone: #690101 !important;
    --redBackground: #ffeded !important;
    --option: #f8f9f9 !important;
    --optionhover: #f6f6f6 !important;
    --white: #fff !important;
    --disabledbutton: #c8d3dc !important;
    --stroke: #eceeef !important;
    --border-bottom: #4990ef !important;
    --dark-blue: #334c8b !important;
    --dark-bluetwo: #03224c !important;
    --darkbluethree: #213757 !important;
    --green: #6fcf97 !important;
    --greyborder: #e3e5e7;
    --greytext: #dbe0eb;
    --hover: #0b6aea !important;
    --graybackground: #e5e5e5 !important;
    --errorborder: #ff4c4c !important;
    --errorbackground: #fff2f2 !important;
    --info: #445261 !important;
    --statusbackground: #e4f2ff !important;
    --blueone: #2484e8 !important;
    --bluetwo: #56ccf2 !important;
    --orange: #f7aa00 !important;
    --voilet: #2d215d !important;
    --pink1: #f7ddd8 !important;
    --tertiarybackground: #f9fafe !important;
    --yellowone: #ffd54f !important;
    --secondary: #ed1164 !important;
    --grey1: #939598 !important;
    --grey2: #6D6E71 !important;
    --ternary1: #005A4B !important;

}

$font-family-base: "Lato", sans-serif;
